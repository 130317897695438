import { useEffect, useState } from "react";

import AuthProviders from "@/components/site/auth/Providers";
import Error from "@/components/site/Error";
import env from "@/utils/env";
import errors from "@/utils/errors";
import mixpanel from "@/utils/mixpanel";
import { signIn } from "next-auth/react";
import { useForm } from "react-hook-form";
import { useRouter } from "next/router";

const defaultEmail = env.isDev() ? "manmademammal@gmail.com" : undefined;

export default function LoginForm() {
  const router = useRouter();
  const { query } = router;
  const { error } = query;
  const { register, handleSubmit } = useForm();
  const [unknownError, setUnknownError] = useState(false);
  const [authError, setAuthError] = useState("");

  useEffect(() => {
    if (error) {
      const knownError = (errors as any)[error as string];

      if (knownError) {
        setAuthError(knownError);
      } else {
        setUnknownError(true);
      }
    }
  }, [router, error]);

  type IOnSubmit = { email: string };
  const onSubmit = async (data: IOnSubmit) => {
    mixpanel.track("form submit: Log in with email");

    setAuthError("");
    setUnknownError(false);

    try {
      await signIn("email", {
        email: data.email,
        redirect: false,
      });

      router.push(`/confirm?mode=login&email=${encodeURIComponent(data.email)}`);
    } catch (error) {
      mixpanel.track("form submit error: Log in with email", { error });

      setUnknownError(true);
    }
  };

  return (
    <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
      {authError ? <Error>{authError}</Error> : null}
      {unknownError ? <Error>Something went wrong. Please try again.</Error> : null}

      <div>
        <label htmlFor="email" className="sr-only">
          Email Address
        </label>

        <input
          defaultValue={defaultEmail}
          id="email"
          type="email"
          autoComplete="off"
          placeholder="Email Address"
          className="block w-full px-3 py-3 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
          {...register("email", { required: true })}
        />
      </div>

      <div>
        <button
          type="submit"
          className="flex justify-center w-full px-5 py-3 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Continue
        </button>
      </div>

      <div className="relative">
        <div className="absolute inset-0 flex items-center">
          <div className="w-full border-t border-gray-300" />
        </div>

        <div className="relative flex justify-center text-sm">
          <span className="px-2 text-gray-500 bg-white">Or continue with</span>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-3 mt-6">
        <AuthProviders />
      </div>
    </form>
  );
}
