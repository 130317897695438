import type { GetServerSidePropsContext, NextPage } from "next";

import Footer from "@/components/site/Footer";
import Head from "next/head";
import Header from "@/components/site/Header";
import LoginForm from "@/components/site/login/LoginForm";
import SignupBanner from "@/components/site/login/SignupBanner";
import { redirectLoggedInUserToApp } from "@/utils/auth";

type Props = {};

const Login: NextPage<Props> = () => {
  return (
    <>
      <Head>
        <title>manmademammal</title>
        <meta name="description" content="welcome to mammal house" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <div className="fixed z-50 w-full py-6 bg-white border-b border-gray-200 bg-opacity-80 sm:backdrop-filter backdrop-blur-sm backdrop-saturate-150">
        <Header />
      </div>

      <div className="flex flex-col justify-center flex-1 min-h-screen">
        <div className="mt-auto text-center sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-auto text-4xl font-bold text-center text-gray-900">
            Log in to your account
          </h2>
        </div>

        <div className="mt-1 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="px-4 py-8 bg-white sm:rounded-lg sm:px-10">
            <LoginForm />
          </div>
        </div>

        <div className="mt-auto">
          <SignupBanner />
        </div>
      </div>

      <Footer />
    </>
  );
};

export const getServerSideProps = async (context: GetServerSidePropsContext) => {
  const shouldRedirect = await redirectLoggedInUserToApp(context);
  if (shouldRedirect) return shouldRedirect;

  return {
    props: {},
  };
};

export default Login;
