import Link from "next/link";

export default function SignupBanner() {
  return (
    <div className="w-full py-12 overflow-hidden border-t border-b bg-gray-50">
      <nav className="flex flex-wrap justify-center -mx-5 -my-2" aria-label="Footer">
        <div className="px-5 py-2">
          <Link href="/signup">
            <a className="text-base text-indigo-500 hover:underline">
              Don&apos;t have an account? Sign Up
            </a>
          </Link>
        </div>
      </nav>
    </div>
  );
}
